import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid2,
  CircularProgress,
  Paper,
  Autocomplete,
  TextField,
  Typography,
  CardContent,
  Card
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

function EmailInterests() {
  const { user } = useUser();
  const partnerSite = user?.publicMetadata?.partnerSite || false;
  const location = useLocation()
  const data = location.state || {};
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audiences, setAudiences] = useState([])
  const [audience, setAudience] = useState(data.audience || '')
  const [interests, setInterests] = useState([])
  const [interest, setInterest] = useState()
  const [emailLoading, setEmailLoading] = useState(true)
  const [startDate, setStartDate] = useState(dayjs().subtract(14, 'day'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
  const [filterType, setFilterType] = useState("audience")

  const columns = [
    { field: 'audience', headerName: 'Audience', flex: 2 },
    { field: 'name', headerName: 'Interest Group', flex: 2 },
    { field: 'date', headerName: 'Reporting Date', flex: 2 },
    { field: 'count', headerName: 'Subscribers', flex: 1.5 },
  ];

  useEffect(() => {
    if (audience) {
      setLoading(true)
      fetch(`/api/mailchimp-audiences?audience=${audience}&startDate=${startDate}&endDate=${endDate}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data)
          setInterests(data.interestsArray || [])
          setLoading(false)
          console.log(data)
        })
    } else if (interest) {
      setLoading(true)
      fetch(`/api/mailchimp-audiences?interest=${interest}&startDate=${startDate}&endDate=${endDate}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data)
          setLoading(false)
        })
    } else {
      setLoading(true)
      fetch(`/api/audiences?api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f&partnerSite=${partnerSite}`)
        .then((res) => res.json())
        .then((data) => {
          let audienceResults = []
          data.forEach((item => audienceResults.push(item.name)))
          setAudiences(audienceResults || [])
          setEmailLoading(false)
        })
        .catch((error) => console.error('Error fetching audiences:', error));
      fetch(`/api/mailchimp-audiences?startDate=${startDate}&endDate=${endDate}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data)
          setInterests(data.interestsArray || [])
          setLoading(false)
        })
    }
  }, [audience, interest, startDate, endDate, partnerSite]);

  return (
    <Container maxWidth="lg">
      <Grid2
        container
        spacing={3}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
          {filterType === "audience" && (
            <Autocomplete
              disablePortal
              renderInput={(params) => <TextField {...params} label="Audience" />}
              options={audiences}
              value={audience}
              onInputChange={(e, value, reason) => { if (reason === "selectOption") { setAudience(value) } else if (reason === "clear") { setAudience(''); setFilterType('interest') } }}
              loading={emailLoading}
            />
          )}
          {filterType === "interest" && (
            <Autocomplete
              disablePortal
              renderInput={(params) => <TextField {...params} label="Interests" />}
              options={interests}
              value={interest}
              onInputChange={(e, value, reason) => { if (reason === "selectOption") { setInterest(value) } }}
              loading={emailLoading}
            />
          )}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => {
              setStartDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(2, "day")}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
          <DatePicker
            fullWidth
            label="End Date"
            value={endDate}
            onChange={(newDate) => {
              setEndDate(newDate)
            }}
            sx={{ width: "100%" }}
            maxDate={dayjs().subtract(1, "day")}
          />
        </Grid2>
        {results && filterType === "interest" && (
          <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }}>
            <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
              <Card variant="outlined">
                <CardContent>
                  <Grid2 container spacing={0} >
                    <Grid2 container direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ mx: 'auto' }}
                    >
                      <Typography variant="caption">Subscribers on start date</Typography>
                      <Typography variant="h4">{Number(results.startDateSubscribers).toLocaleString()}</Typography>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
              <Card variant="outlined">
                <CardContent>
                  <Grid2 container spacing={0} >
                    <Grid2 container direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ mx: 'auto' }}
                    >
                      <Typography variant="caption">Subscribers on end date</Typography>
                      <Typography variant="h4">{Number(results.endDateSubscribers).toLocaleString()}</Typography>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        results ? (
          <>
            <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
              <DataGrid rows={results.interests} columns={columns} />
            </Paper>
          </>
        ) : (
          <></>
        )
      )}
    </Container >
  );
}

export default EmailInterests;
