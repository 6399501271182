import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    CircularProgress,
    Card,
    CardContent,
    Paper,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUser } from '@clerk/clerk-react';
import Home from './home';

function NetworkDashboard() {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const { user } = useUser();
    const parentSite = user?.publicMetadata?.parentSite || "1";
    const role = user?.publicMetadata?.role;
    const singleSiteUser = user?.publicMetadata?.singleSiteUser || false;

    const networkSites = [
        {
            field: 'site', headerName: 'Site', flex: 2, renderCell: (params) => (
                <Link
                    to={`/editorial/analytics`}
                    state={{
                        site: params.value,
                        startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
                        endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`
                    }}
                >
                    {params.value}
                </Link>
            )
        },
        { field: 'total', headerName: 'Story Count', flex: 1.5 },
        { field: 'views', headerName: 'Pageviews', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        {
            field: 'underPerformingTotal', headerName: '< 200 PVs Count', flex: 1.5, renderCell: (params) => (
                <Link
                    to={`/editorial/analytics`}
                    state={{
                        site: params.row.site,
                        underperforming: true,
                        startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
                        endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`
                    }}
                >
                    {params.value}
                </Link>
            )
        }
    ];

    useEffect(() => {
        // Fetch audiences on component mount
        setLoading(true)
        fetch(`/api/network-analytics?parentSite=${parentSite}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`)
            .then((res) => res.json())
            .then((data) => {
                setResults(data)
                setLoading(false)
            })
            .catch((error) => console.error('Error fetching audiences:', error));
    }, [startDate, endDate, parentSite]);

    if (role === "sales" || singleSiteUser) {
        return (
            <Home />
        )
    }

    return (

        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        fullWidth
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                        }}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        fullWidth
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                        }}
                        maxDate={dayjs().subtract(1, "day")}
                    />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                !singleSiteUser ? (results ? (
                    <> <Grid2 container spacing={3} marginTop={3}>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                            <Card variant="elevation" raised={true}>
                                <CardContent>
                                    <Typography variant="caption">Total Pageviews</Typography>
                                    {results.totalViews && (
                                        <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                            <Card variant='elevation' raised={true}>
                                <CardContent>
                                    <Typography variant="caption">Total Sessions</Typography>
                                    {results.totalSessions && (
                                        <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                            <Card variant="elevation" raised={true}>
                                <CardContent>
                                    <Typography variant="caption">Total Posts</Typography>
                                    {results.totalCount && (
                                        <Typography variant="h4">{results.totalCount.toLocaleString()}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                            <Card variant="elevation" raised={true}>
                                <CardContent>
                                    <Typography variant="caption">Total Posts &lt; 200 PVs</Typography>
                                    {results.totalUnderPerformingTotal && (
                                        <Typography variant="h4">{results.totalUnderPerformingTotal.toLocaleString()}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid2>
                    </Grid2>
                        <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
                            <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.resultsArray} columns={networkSites} />
                        </Paper>
                    </>
                ) : (
                    <></>
                )) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default NetworkDashboard;
