import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

function SignupForms() {
  const { user } = useUser();
  const userId = user?.id;
  const partnerSite = user?.publicMetadata?.partnerSite || false;
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState('');
  const [resubscribeForms, setResubscribeForms] = useState([]);

  useEffect(() => {
    // Fetch audiences on component mount
    fetch(`/api/audiences?partnerSite=${partnerSite}&userId=${userId}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
      .then((res) => res.json())
      .then((data) => {
        setAudiences(data || []);
      })
      .catch((error) => console.error('Error fetching audiences:', error));
  }, [partnerSite, userId]);

  useEffect(() => {
    // Fetch interest groups when an audience is selected
    if (selectedAudience) {
      fetch(`/api/resubscribes?listId=${selectedAudience}&partnerSite=${partnerSite}&userId=${userId}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
        .then((res) => res.json())
        .then((data) => {
          console.log('Signup Forms API response:', data);
          setResubscribeForms(data);
        })
        .catch((error) => console.error('Error fetching signup forms:', error));
    } else {

    }
  }, [selectedAudience, partnerSite, userId]);


  return (
    <Container maxWidth="lg">
      <FormControl fullWidth margin="normal">
        <InputLabel id="audience-select-label">Audience</InputLabel>
        <Select
          labelId="audience-select-label"
          value={selectedAudience}
          label="Audience"
          onChange={(e) => {
            setSelectedAudience(e.target.value);
          }}
        >
          {audiences.map((audience) => (
            <MenuItem key={audience.id} value={audience.id}>
              {audience.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {resubscribeForms.map((form) => (
        <Link to={form.signup_form_url} target='_blank'><Button>Signup Form</Button></Link>
      ))}
    </Container>
  );
}

export default SignupForms;
