import React, { useState, useEffect } from 'react';
import {
    Container,
    CircularProgress,
    Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function Readers() {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);

    const networkSites = [
        {
            field: 'site', headerName: 'Site', flex: 2
        },
        { field: 'count', headerName: 'Active Reader Count', flex: 1.5 },
    ];

    useEffect(() => {
        // Fetch audiences on component mount
        setLoading(true)
        fetch(`/api/reader-count?api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
            .then((res) => res.json())
            .then((data) => {
                setResults(data)
                setLoading(false)
            })
            .catch((error) => console.error('Error fetching audiences:', error));
    }, []);

    return (
        <Container maxWidth="lg">
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        <Paper elevation={5} sx={{ marginTop: '20px', height: '800px' }}>
                            <DataGrid style={{ marginTop: '20px', height: '800px' }} rows={results.results} columns={networkSites} />
                        </Paper>
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default Readers;
