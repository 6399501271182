import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function StreamingReport() {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));

    const contentColumns = [
        {
            field: 'title', headerName: 'Title', flex: 3, renderCell: (params) => {
                return <span dangerouslySetInnerHTML={{ __html: params.value }} />
            }
        },
        {
            field: 'autoplay', headerName: 'Autoplay', flex: 1.5, renderCell: (params) => {
                return Number(params.value) ? Number(params.value).toLocaleString() : 0
            }
        },
        {
            field: 'milestone_25', headerName: '25% Completion', flex: 1.5, renderCell: (params) => {
                return Number(params.value).toLocaleString()
            }
        },
        {
            field: 'milestone_50', headerName: '50% Completion', flex: 1.5, renderCell: (params) => {
                return Number(params.value).toLocaleString()
            }
        },
        {
            field: 'milestone_75', headerName: '75% Completion', flex: 1.5, renderCell: (params) => {
                return Number(params.value).toLocaleString()
            }
        },
        {
            field: 'complete', headerName: 'Complete', flex: 1.5, renderCell: (params) => {
                return Number(params.value) ? Number(params.value).toLocaleString() : 0
            }
        }
    ];

    useEffect(() => {
        setLoading(true)
        fetch(`/api/streaming-analytics?startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
            .then((res) => res.json())
            .then((data) => {
                setResults(data || [])
                setLoading(false)
            })
            .catch((error) => console.error('Error fetching audiences:', error));
    }, [startDate, endDate]);

    return (
        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>&nbsp;</Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }}
                        maxDate={dayjs().subtract(1, "day")} />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 size={{ xs: 12, sm: 4, md: 4 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid2 container spacing={0} >
                                            <Grid2 container direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{ mx: 'auto' }}
                                            >
                                                <Typography variant="caption">Total Autoplays</Typography>
                                                <Typography variant="h4">{Number(results.totalAutoplays).toLocaleString()}</Typography>
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 4, md: 4 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid2 container spacing={0} >
                                            <Grid2 container direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{ mx: 'auto' }}
                                            >
                                                <Typography variant="caption">Total Click to Start</Typography>
                                                <Typography variant="h4">{Number(results.totalClicks).toLocaleString()}</Typography>
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 4, md: 4 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid2 container spacing={0} >
                                            <Grid2 container direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{ mx: 'auto' }}
                                            >
                                                <Typography variant="caption">Total Completions</Typography>
                                                <Typography variant="h4">{Number(results.totalCompletions).toLocaleString()}</Typography>
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        </Grid2>
                        <Typography variant="h4" style={{ marginTop: '20px' }}>Video Streams</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.resultsArray} columns={contentColumns} />
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default StreamingReport;
